@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

@layer base {
  /* Réinitialisation globale des marges, paddings et box-sizing */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
   /* Application de la police générale */
   html {
    font-family: 'Poppins', sans-serif;
  }
}

/* Animation bounce-twice logo Header*/
@keyframes bounce-twice {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.start-animation {
  animation: bounce-twice 0.8s ease-in-out 2;
}


